<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
// import { required, email, minLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
// import Swal from "sweetalert2";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Usuários",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Usuários",
      items: [
        {
          text: "Usuários",
          active: true
        }
      ],
      users: [],
      submitted: false,
      showmodal: false,
      checked: true,
      loading: true,
      search: null,
      options: [],
      newUser: {
          "name": null,
          "identity": null,
          "email": null,
          "phone": null,
          "password": null
      }
    };
  },
  validations: {
    newUser: {
      name: { required },
      phone: { required },
      identity: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
    }
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getData()
  },
  methods:{
    getData(url){
      this.loading = true
      let config = {
        method: 'get',
        url: url ? url : `${process.env.VUE_APP_API}api/v1/admin/user`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        }
      };

      axios(config)
      .then( (response) => {
        this.loading = false
        this.users = response.data.data
        
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true

        let config = {
          method: 'POST',
          url: `${process.env.VUE_APP_API}api/v1/admin/user`,
          headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${this.user.access_token}`,
          },
          data: this.newUser
        };
        axios(config)
        // eslint-disable-next-line no-unused-vars
        .then( response => {
          this.loading = false
          this.submitted = false
          this.newUser = {
              "name": null,
              "identity": null,
              "email": null,
              "phone": null,
              "password": null
          }
          this.$bvToast.toast('Usuário adicionado com sucesso', {
            title: 'Tudo certo!',
            variant: 'success',
            solid: true
          });
          this.showmodal = false;
          this.getData()
        })
        // eslint-disable-next-line no-unused-vars
        .catch( (error) => {
          this.loading = false
          let er = null
          Object.entries(error.response.data?.data).forEach( item => {
            item[1].map( value => {
              if (er) {
                er = er + ', ' + value
              }else{
                er = value
              }
              
            })
          })
            console.log(er)
          this.loading = false

          this.$bvToast.toast(er, {
            title: 'Verifique os campos',
            variant: 'danger',
            solid: true,
            escapeMarkup: true
          });
        });
      }
      
    },
    paginate(value){
      let link = this.users.links[value].url
      this.getData(link)
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },
    async fetchOptions (search, loading) {
      if (search.length < 3) return false;
      loading(true)
      let config = {
        method: 'GET',
        url: `${process.env.VUE_APP_API}api/v1/autocomplete/users?search=${search}`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`,
        }
      };
      await axios(config)
      .then( response => {
        this.options = []
        response.data.data.map( item => {
          if (item.identity) {
            this.options.push({label: `${item.name} (${item.identity})`, code: item.uuid})
          }
        })

        loading(false)
      })

    },
    setSelected(){
      this.options = []
    },
    onQueryChange() {
      if (this.search.length == 0) {
        this.getData()
      }
    }
  },
  watch: {
    search: function (val) {
      if (val.length >= 3) {
        this.getData(`${process.env.VUE_APP_API}api/v1/admin/user?search=${val}`)
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-3">
        <div class="card h-100">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Filtros</h5>
          </div>

          <div class="card-body border-top">
            <div>
              <h5 class="font-size-14 mb-4">Buscar</h5>
              <b-form-group
                id="example-search"
              >
                <b-form-input
                  id="search"
                  type="search"
                  v-model="search"
                  @input="onQueryChange"
                ></b-form-input>
              </b-form-group>
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="card h-100">
          
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Novo usuário
              </a>
            </div>
            
            <div class="table-responsive mt-3">
              <b-overlay :show="loading" rounded="sm">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 20px;">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customercheck" />
                          <label class="custom-control-label" for="customercheck">&nbsp;</label>
                        </div>
                      </th>
                      <th>CPF</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Criado em</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in users.data" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customercheck${index}`"
                          />
                          <label class="custom-control-label" :for="`customercheck${index}`">&nbsp;</label>
                        </div>
                      </td>
                      <td>{{item.identity}}</td>
                      <td>{{item.name}}</td>
                      <td>{{item.email}}</td>
                      <td>
                        {{item.created_at | date}}
                      </td>
                      <td>
                        <router-link
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Visualizar"
                          :to="`/user/${item.uuid}`"
                        >
                          <i class="mdi mdi-eye font-size-18"></i>
                        </router-link>
                        <!-- <a
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Excluir"
                          @click="deleteAdmin(item.uuid)"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>

              <b-pagination
                class="mt-4"
                v-model="users.current_page"
                :total-rows="users.total"
                :per-page="users.per_page"
                aria-controls="my-table"
                align="center"
                @change="paginate"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Adicionar usuário"
      title-class="text-dark font-18"
      hide-footer
    >
      <b-overlay :show="loading" rounded="sm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label>Nome</label>
            <input v-model="newUser.name" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.newUser.name.$error }"/>
            <div
              v-if="submitted && !$v.newUser.name.required"
              class="invalid-feedback"
            >O nome é obrigatório</div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              id="email"
              v-model="newUser.email"
              type="email"
              name="email"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.newUser.email.$error }"
            />
            <div v-if="submitted && $v.newUser.email.$error" class="invalid-feedback">
              <span v-if="!$v.newUser.email.required">Email é obrigatório</span>
              <span v-if="!$v.newUser.email.email">Email inválido</span>
            </div>
          </div>
          <div class="form-group">
            <label>CPF</label>
            <input v-model="newUser.identity" type="text" v-mask="'###.###.###-##'" class="form-control" name="cpf" :class="{ 'is-invalid': submitted && $v.newUser.identity.$error }"/>
            <div
              v-if="submitted && !$v.newUser.identity.required"
              class="invalid-feedback"
            >O CPF é obrigatório</div>
          </div>
          <div class="form-group">
            <label>Telefone</label>
            <input v-model="newUser.phone" type="text" v-mask="'(##) #####-####'" class="form-control" :class="{ 'is-invalid': submitted && $v.newUser.phone.$error }"/>
            <div
              v-if="submitted && !$v.newUser.phone.required"
              class="invalid-feedback"
            >O telefone é obrigatório</div>
          </div>
          <div class="form-group">
            <label for="position">Senha</label>
            <input
              id="position"
              v-model="newUser.password"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.newUser.password.$error }"
            />
            <div
              v-if="submitted && !$v.newUser.password.required"
              class="invalid-feedback"
            >A senha é obrigatória</div>
            <div
              v-if="submitted && !$v.newUser.password.minLength"
              class="invalid-feedback"
            >A senha deve conter no mínimo 6 caracteres</div>
          </div>
          
          
          <div class="text-right">
            <button type="submit" class="btn btn-success">Salvar</button>
            <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
          </div>
        </form>
      </b-overlay>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>